import React, { useState, useEffect } from "react";
import moment from "moment";
import { Col, Row, Image } from "react-bootstrap";
import infoCircle from "../../assets/images/info-circle.png"

const CountdownTimer: React.FC<{
	futureUnixTimestampTitle: string;
	futureUnixTimestamp: number;
	totalStakedTitle: string;
	totalStaked: number;
	claimableTokens: number;
	Staked: number;
}> = ({ futureUnixTimestampTitle, futureUnixTimestamp, totalStakedTitle, totalStaked, claimableTokens, Staked }) => {
	const [remainingTime, setRemainingTime] = useState<string>("");
	useEffect(() => {
		const updateRemainingTime = () => {
			if (futureUnixTimestamp === 0) {
				setRemainingTime("Not started Yet");
			}
			const now = moment();
			const futureDate = moment.unix(futureUnixTimestamp);
			const duration = moment.duration(futureDate.diff(now));

			if (duration.asSeconds() <= 0) {
				setRemainingTime("Time is up!");
				return;
			}

			const days = Math.floor(duration.asDays());
			const hours = duration.hours();
			const minutes = duration.minutes();
			const seconds = duration.seconds();
			// Build the shorter format string
			let timeLeft = "";
			if (days > 0) timeLeft += `${days}d `;
			if (hours > 0) timeLeft += `${hours}h `;
			if (minutes > 0) timeLeft += `${minutes}m `;
			if (seconds > 0) timeLeft += `${seconds}s`;

			timeLeft.trim();
			setRemainingTime(
				timeLeft.trim()
			);
		};
		updateRemainingTime();
		const intervalId = setInterval(updateRemainingTime, 1000);
		return () => clearInterval(intervalId);
	}, [futureUnixTimestamp]);

	return (
		<>
			<Row>
				<Col md="6" className="mb-sm-4 mb-3">
					<div className="countdown-timer">
						<div className="countdown-timer-info">
							<div className="d-flex align-items-center justify-content-between">
								<h3 className="mb-0 text-white">{totalStakedTitle}</h3>
								<div className="info-circle position-relative">
									<Image src={infoCircle} alt="info circle" />
									<div className="stack-tooltip-content countdown-timer position-absolute">
										<div className="countdown-timer-info">
											<h3 className="text-white">{totalStakedTitle}</h3>
										</div>
										<p className="text-white text-white-light">The total number of tokens you have locked in staking.</p>
									</div>
								</div>
							</div>
						</div>
						<span className="text-white text-white-light">{totalStaked}</span>
					</div>
				</Col>
				<Col md="6" className="mb-sm-4 mb-3">
					<div className="countdown-timer">
						<div className="countdown-timer-info">
							<div className="d-flex align-items-center justify-content-between">
								<h3 className="mb-0 text-white">Current Staked</h3>
								<div className="info-circle position-relative">
									<Image src={infoCircle} alt="info circle" />
									<div className="stack-tooltip-content countdown-timer position-absolute">
										<div className="countdown-timer-info">
											<h3 className="text-white">Currently Staked</h3>
										</div>
										<p className="text-white text-white-light">The number of tokens you currently have locked in staking.</p>
									</div>
								</div>
							</div>
						</div>
						<span className="text-white text-white-light">{Staked}</span>
					</div>
				</Col>
				<Col md="6" className="mb-sm-4 mb-3">
					<div className="countdown-timer">
						<div className="countdown-timer-info">
							<div className="d-flex align-items-center justify-content-between">
								<h3 className="mb-0 text-white">{futureUnixTimestampTitle}</h3>
								<div className="info-circle position-relative">
									<Image src={infoCircle} alt="info circle" />
									<div className="stack-tooltip-content countdown-timer position-absolute">
										<div className="countdown-timer-info">
											<h3 className="text-white">{futureUnixTimestampTitle}</h3>
										</div>
										<p className="text-white text-white-light">The amount of time left until your staking period ends.</p>
									</div>
								</div>
							</div>
						</div>
						<span className="text-white text-white-light">{remainingTime}</span>
					</div>
				</Col>
				<Col md="6" className="mb-sm-4 mb-3">
					<div className="countdown-timer">
						<div className="countdown-timer-info">
							<div className="d-flex align-items-center justify-content-between">
								<h3 className="mb-0 text-white">Claimed Tokens</h3>
								<div className="info-circle position-relative">
									<Image src={infoCircle} alt="info circle" />
									<div className="stack-tooltip-content countdown-timer position-absolute">
										<div className="countdown-timer-info">
											<h3 className="text-white">Claimed Tokens</h3>
										</div>
										<p className="text-white text-white-light">The total number of staking rewards you have successfully claimed.</p>
									</div>
								</div>
							</div>
						</div>
						<span className="text-white text-white-light">{claimableTokens}</span>
					</div>
				</Col>

			</Row>
		</>
	);
};

export default CountdownTimer;
