import { Connection } from "@solana/web3.js";
import { AnchorProvider, Program } from "@coral-xyz/anchor";
import { Locking } from "../types/locking";
import Idl from "../IDL/idl.json";

const programInstance = (connection: Connection, wallet: any) => {
  const idlData: any = Idl;
  const provider = new AnchorProvider(connection, wallet, {
    preflightCommitment: "processed",
  });
  const program = new Program(idlData, provider) as Program<Locking>;
  return program;
};

export { programInstance };
