import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useState, useEffect, useCallback } from "react";
import RewardsCountdownTimer from "../Counter/index";
import { enqueueSnackbar } from "notistack";
import { Col, Row } from "react-bootstrap";
import Loader from "../Loader/loader";
import { fetchTokenBalance, getRewardInfo } from "src/util/program/functions";
import { initialAndAddReward } from "src/util/program/rewards";

const Reward = () => {
	const [amount, setAmount] = useState<number>(0);
	const [Staked, setStaked] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const [totalStaked, setTotalStaked] = useState<number>(0);
	const [remainingTime, setRemainingTime] = useState<number>(0);
	const [claimableTokens, setClaimableTokens] = useState<number>(0);
	const [availableToken, setAvailableToken] = useState(0)
	const wallet = useWallet();
	const { publicKey } = useWallet();
	const { connection } = useConnection();

	const getSTakerProfileResponse = useCallback(async () => {
		if (!publicKey?.toBase58()) {
			setTotalStaked(0);
			setStaked(0);
			setClaimableTokens(0);
			setRemainingTime(0);
			return;
		}
		setLoading(true);

		const res = await getRewardInfo(connection, wallet);

		if (res) {
			setTotalStaked(res?.totalRewards?.toNumber());
			// setStaked(res?.stakeAmount?.toNumber());
			setClaimableTokens(res?.totalClaimedReward);
			const time = res?.lastUpdated?.toNumber();
			setRemainingTime(time);
		} else {
			setRemainingTime(0);
		}
		const balance = await fetchTokenBalance(publicKey, connection)
		setAvailableToken(balance)
		setLoading(false);
	}, [publicKey?.toBase58(), connection]);

	const stakeTokenFunc = async () => {
		if (!publicKey) {
			enqueueSnackbar("Please connect your wallet", {
				variant: 'error', autoHideDuration: 6000
			});
			return;
		}

		if (amount < 0) {
			enqueueSnackbar("Amount must be greater than zero", {
				variant: 'error', autoHideDuration: 6000
			});
			return;
		}

		if (amount > availableToken) {
			enqueueSnackbar(`You don't have enough tokens. Available: ${availableToken.toFixed(2)}`, {
				variant: 'error', autoHideDuration: 6000
			});
			return;
		}

		setLoading(true);

		await initialAndAddReward(connection, wallet, amount);
		getSTakerProfileResponse();
		setAmount(0)
		setLoading(false);
	};

	useEffect(() => {

		getSTakerProfileResponse();
	}, [publicKey?.toBase58(), connection]);

	return (
		<>
			{
				loading ?
					(
						<Loader />
					) : (
						<div className="stake-container main-padding-area">
							<div className="container-width">
								<Row>
									<Col lg="5" className="mb-lg-0 mb-sm-4 mb-3">
										<div className="countdown-timer pb-3">
											<div className="countdown-timer-info">
												<h3 className="text-white">Add Rewards Tokens</h3>
												<p className="text-white text-white-light mb-0">Add rewards to incentivize staking and support the network.</p>
											</div>
											<input
												type="number"
												value={amount + ""}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAmount(Number(e.target.value))}
												placeholder="Enter amount"
												className="stake-input mb-3"
												width={"80%"}
												min={0}
											/>
											<div className="d-flex justify-content-between align-items-center p-3  rounded">
												<p className="text-white mb-0">
													Token balance: {availableToken.toLocaleString("en-US")}
												</p>
												<button
													className="btn btn-primary btn-sm max-btn"
													onClick={() => setAmount(availableToken)}
												>
													Max
												</button>
											</div>

											<button disabled={amount === 0 ? true : false} onClick={stakeTokenFunc} className="btn-green-gradient">
												Add Reward
											</button>
										</div>
									</Col>
									<Col lg="7">
										<div>
											<RewardsCountdownTimer
												futureUnixTimestamp={remainingTime || 0}
												claimableTokens={claimableTokens || 0}
												totalStaked={totalStaked || 0}
												Staked={Staked || 0}
											/>
										</div>
									</Col>
								</Row>
							</div>
						</div>
					)
			}
		</>
	);
};

export default Reward;