import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { getOwnerAddress } from "../../util/program/functions";
import toggleIcon from "../../assets/images/toogle-icon.png";
import { Link, useLocation } from "react-router-dom";
import { Image, Nav, Navbar, NavDropdown } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import { useEffect, useRef, useState } from "react";

const Header = () => {
	const [adminAccount, setAdminAccount] = useState("GWr5BZALAAGJQNrQMoTvpQ7GfMFjGDvnqabpE2TY9C8C");

	const { publicKey } = useWallet();
	const { connection } = useConnection();

	const wallet = useWallet();
	const location = useLocation();
	const collapseRef = useRef<HTMLDivElement | null>(null);

	const getownerDetails = async () => {
		const address = await getOwnerAddress(connection, wallet);
		if (address)
			setAdminAccount(address);
	};

	useEffect(() => {
		getownerDetails();
	}, []);

	useEffect(() => {
		if (collapseRef.current) {
			collapseRef.current.classList.remove("show");
		}
	}, [location.pathname]);
	return (
		<header id="header">
			<Navbar expand="lg" className="container-width navbar-wrapper">
				<div className="header-list-area d-flex align-items-center justify-content-between">
					<Link to="/" className="logo">
						<Image src={Logo} alt="logo" fluid />
					</Link>
					<Navbar.Toggle
						aria-controls="navbarScroll"
					>
						<Image src={toggleIcon} alt="toggleicon" />
					</Navbar.Toggle>
					<Navbar.Collapse id="navbarScroll" className="header-list-wrapper" ref={collapseRef}>
						<Nav className="header-list">
							<ul className="d-lg-flex align-items-center justify-content-center m-0 p-0">
								<li className={location.pathname === "/" ? "active" : ""}>
									<Link to="/">Home</Link>
								</li>
								{
									// publicKey?.toBase58() === adminAccount &&
									// (
									<>
										{/* <li className={location.pathname === "/admin" ? "active" : ""}>
											<Link to="/admin">Vault</Link>
										</li> */}
										{/* <li className="">
											<Link to="/apy">Apy</Link>
										</li> */}
										{/* <li className={location.pathname === "/change-owner" ? "active" : ""}>
											<Link to="/change-owner">Change Owner</Link>
										</li> */}

										{/* <li className="">
											<Link to="/add-reward">Add Reward</Link>
										</li> */}

									</>
									// )
								}
								<li className={location.pathname === "/tiers-details" ? "active" : ""}>
									<Link to="/tiers-details">Tiers Details</Link>
								</li>
								<li className={location.pathname === "/stake" ? "active" : ""}>
									<Link to="/stake">Stake</Link>
								</li>
								<li className="">
									<Link to="/claim-reward">Claim Reward</Link>
								</li>
								<li className={location.pathname === "/staker-history" ? "active" : ""}>
									<Link to="/staker-history">Staker History</Link>
								</li>

								{/* <li className={location.pathname === "/un-stake" ? "active" : ""}>
									<Link to="/un-stake">unStake</Link>
									
								</li>*/}
								<NavDropdown title="Admin" id="basic-nav-dropdown" className="header-drop">
									{/* <NavDropdown.Item className="items" as={Link} to="/apy">Apy</NavDropdown.Item> */}
									<NavDropdown.Item className="items" as={Link} to="/add-reward">Add Reward</NavDropdown.Item>
									<NavDropdown.Item className="items" as={Link} to="/change-owner">Change Owner</NavDropdown.Item>
									<NavDropdown.Item className="items" as={Link} to="/Admin">Global Config</NavDropdown.Item>
								</NavDropdown>
							</ul>
						</Nav>
					</Navbar.Collapse>
					<div className="header-wallet-btn">
						<WalletMultiButton />
					</div>
				</div>
			</Navbar>
		</header>
	);
};

export default Header;
