import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { initailizeVault } from "../../util/program/functions";
import { initialTierData, tierNames } from "src/util/consts";
import { enqueueSnackbar } from "notistack";
import Loader from "../Loader/loader";
import { useState } from "react";

const UpdateTier = () => {

	const [loading, setLoading] = useState<boolean>(false);
	const [tierData, setTierData] = useState(
		tierNames.map((tier, index) => initialTierData[index])
	);

	const [currentStep, setCurrentStep] = useState<number>(0);

	const { connection } = useConnection();
	const wallet = useWallet();

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, index: number) => {
		const { name, value, type } = e.target;
		const checked = type === "checkbox" && (e.target as HTMLInputElement).checked;

		const updatedTierData = [...tierData];

		updatedTierData[index] = {
			...updatedTierData[index],
			[name]: type === "checkbox" ? checked : value,
		};
		setTierData(updatedTierData);
	};

	const handleNext = () => {
		// Validate tierData before proceeding to the next step
		if (
			tierData[currentStep]?.clientCommission < 0 ||
			tierData[currentStep]?.clientCommission > 100
		) {
			enqueueSnackbar("Client commission must be between 0 and 100.", {
				variant: "error",
				autoHideDuration: 6000,
			});
			return;
		}

		// If all validations pass, move to the next step
		setCurrentStep((prevStep) => prevStep + 1);
	};

	const handlePrevious = () => setCurrentStep((prevStep) => prevStep - 1);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

		e.preventDefault();

		if (!wallet.publicKey) {
			enqueueSnackbar("Need to connect wallet", {
				variant: "error",
				autoHideDuration: 6000,
			});
			return;
		}

		setLoading(true);
		await initailizeVault(connection, wallet, tierData);
		setLoading(false);
	};

	return (
		<div className="container">
			<div className="countdown-timer-info">
				<h3 className="text-white mb-0">{tierNames[currentStep]}</h3>
			</div>
			<form onSubmit={handleSubmit} className="form">
				<div className="formGroup">
					<label className="label">
						Token Requirement (min $):
					</label>
					<input
						type="number"
						name="tokenRequirement1"
						value={tierData[currentStep].tokenRequirement1}
						onChange={(e) => handleChange(e, currentStep)}
						required
						className="input"
					/>
				</div>

				<div className="formGroup">
					<label className="label">
						Token Requirement (max $):
					</label>
					<input
						type="number"
						name="tokenRequirement2"
						value={tierData[currentStep].tokenRequirement2}
						onChange={(e) => handleChange(e, currentStep)}
						required
						className="input"
					/>
				</div>

				<div className="formGroup">
					<label className="label">
						Commitment Time (in Minutes):
					</label>
					<input
						type="number"
						name="commitmentTime"
						value={tierData[currentStep]?.commitmentTime}
						// value={apyData[currentStep].apy}
						onChange={(e) => handleChange(e, currentStep)}
						placeholder="Enter in Minutes"
						required
						className="input"
					/>
					{/* <select
						name="commitmentTime"
						value={tierData[currentStep].commitmentTime}
						onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleChange(e, currentStep)}
						className="select"
					>
						<option value="5-minutes">5-minutes</option>
						<option value="1-month">1-month</option>
						<option value="6-month">6-month</option>
						<option value="12-month">12-month </option>
					</select> */}
				</div>
				<div className="formGroup">
					<label className="label">
						APY (percentage %):
					</label>
					<input
						type="number"
						name="apy"
						value={tierData[currentStep].apy}
						onChange={(e) => handleChange(e, currentStep)}
						required
						className="input"
					/>
				</div>
				<div className="formGroup">
					<label className="label">
						Client Commission (%):
					</label>
					<input
						type="number"
						name="clientCommission"
						value={tierData[currentStep].clientCommission}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, currentStep)}
						required
						min={0}
						max={100}
						className="input"
					/>
				</div>
				<div className="formGroup">
					<label className="label">
						Referral Program:
					</label>
					<select
						name="referralProgram"
						value={tierData[currentStep].referralProgram}
						onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleChange(e, currentStep)}
						className="select"
					>
						<option value="0">True</option>
						<option value="1">False</option>
						<option value="2">Enhanced Rates</option>
					</select>
				</div>
				<div className="formGroup d-flex align-items-center justify-content-between">
					<label className="labelCheckbox">
						Access to Premium Nodes:
					</label>
					<input
						type="checkbox"
						name="accessToPremiumNodes"
						checked={tierData[currentStep].accessToPremiumNodes}
						onChange={(e) => handleChange(e, currentStep)}
						className="checkbox"
					/>
				</div>

				{loading ? (
					<Loader />
				) : (
					<div className="form-btn-wrapper">
						{currentStep > 0 && (
							<button type="button" onClick={handlePrevious} className="btn-green-gradient">
								Previous
							</button>
						)}
						{currentStep < tierNames.length - 1 ? (
							<button type="button" onClick={handleNext} className="btn-green-gradient">
								Next
							</button>
						) : null}
						{currentStep === 19 && (
							<button type="submit" className="btn-green-gradient">
								Submit
							</button>
						)}
					</div>
				)}
			</form>
		</div>
	);
};

export default UpdateTier;
