import {
  getStakerProfile,
  initializeUserStateAndStakeToken,
} from "../../util/program/staking";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useState, useEffect, useCallback } from "react";
import CountdownTimer from "../Counter/Counter";
import { enqueueSnackbar } from "notistack";
import { Col, Row } from "react-bootstrap";
import Loader from "../Loader/loader";
import { fetchTokenBalance, getTokenPrice } from "src/util/program/functions";
import { getApyData } from "src/util/program/apy";
import APYTable from "./Apy";

const Stake = () => {

  const [amount, setAmount] = useState<number>(0);
  const [Staked, setStaked] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalStaked, setTotalStaked] = useState<number>(0);
  const [remainingTime, setRemainingTime] = useState<number>(0);
  const [claimableTokens, setClaimableTokens] = useState<number>(0);
  const [availableToken, setAvailableToken] = useState(0);
  const [selectedCheckbox, setSelectedCheckbox] = useState<number>(0);
  const [apyData, setApyData] = useState([]);
  const [wifPrice, setWifPrice] = useState<number>(0);

  const fetchPrice = async () => {
    const price = await getTokenPrice();
    setWifPrice(price);
  };
  const wallet = useWallet();
  const { publicKey } = useWallet();
  const { connection } = useConnection();

  const getSTakerProfileResponse = useCallback(async () => {
    if (!publicKey?.toBase58()) {
      setTotalStaked(0);
      setStaked(0);
      setClaimableTokens(0);
      setRemainingTime(0);
      return;
    }
    setLoading(true);

    const res: any = await getStakerProfile(connection, wallet);
    if (res) {
      setTotalStaked(res?.totalStakedToken?.toNumber());
      setStaked(res?.currentStakedToken?.toNumber());
      setClaimableTokens(res?.claimedRewards?.toNumber());
      const time = res?.stakeTime?.toNumber() + res?.lockDuration;
      setRemainingTime(time);
    } else {
      setRemainingTime(0);
    }
    const balance = await fetchTokenBalance(publicKey, connection);
    setAvailableToken(balance);
    setLoading(false);
  }, [publicKey?.toBase58(), connection]);

  const stakeTokenFunc = async () => {
    if (!publicKey) {
      enqueueSnackbar("Please connect your wallet", {
        variant: "error",
        autoHideDuration: 6000,
      });
      return;
    }

    if (amount < 0) {
      enqueueSnackbar("Amount must be greater than zero", {
        variant: "error",
        autoHideDuration: 6000,
      });
      return;
    }

    if (amount > availableToken) {
      enqueueSnackbar(
        `You don't have enough tokens. Available: ${availableToken.toFixed(2)}`,
        {
          variant: "error",
          autoHideDuration: 6000,
        }
      );
      return;
    }

    setLoading(true);

    await initializeUserStateAndStakeToken(
      connection,
      wallet,
      amount,
      selectedCheckbox
    );
    getSTakerProfileResponse();
    setLoading(false);
  };

  const handleCheckboxChange = (id: number) => setSelectedCheckbox(id);
  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Allow only numbers and enforce min value dynamically
    if (/^\d*$/.test(value)) {
      setAmount(Number(value.toString().replace(/^0+/, '')));
    } else {
      setAmount(Number(value))
    }
  };
  useEffect(() => {
    getSTakerProfileResponse();
  }, [publicKey?.toBase58(), connection]);

  const fetchData = useCallback(async () => {
    const tiersD: any = await getApyData(connection, wallet);
    await fetchPrice()
    console.log(tiersD, "transformedData")
    setApyData(tiersD);
  }, [wallet, connection]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="stake-container main-padding-area">
          <div className="container-width">
            <Row>
              <Col lg="5" className="mb-lg-0 mb-sm-4 mb-3">
                <div className="countdown-timer pb-3">
                  <div className="countdown-timer-info">
                    <h3 className="text-white">Stake Your Tokens</h3>
                    <p className="text-white text-white-light mb-0">
                      Lock your tokens to earn rewards and support the network.
                    </p>
                  </div>
                  {/* <div className="stake-radio-btn-wrapper mb-2">
                    <div className="radio-group ">
                      {apyData && apyData?.length > 0 && apyData.map((item: any, index: number) => (

                        <div
                          key={`${item.apy}-${index}`}
                          onClick={() => handleCheckboxChange(index)}
                        >
                          <input
                            type="radio"
                            id={`option-${index}`}
                            name="slippage"
                            value={item.apy}
                            className="radio-input"
                            checked={selectedCheckbox === index}
                          />
                          <label
                            htmlFor={`option-${index}`}
                            className="radio-label"
                          >
                            <div className="days-label">{item.commitmentTime} Days</div>
                            <div className="apy-label">{item.apy}%</div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div> */}

                  <input
                    type="number"
                    value={"" + amount}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAmountChange(e)}
                    placeholder="Enter amount"
                    className="stake-input mb-3"
                    min={0}
                  />
                  <div className="d-flex justify-content-between align-items-center p-3  rounded">
                    <p className="text-white mb-0">
                      Token balance: {availableToken.toLocaleString("en-US")}
                    </p>

                    <button
                      className="btn btn-primary btn-sm max-btn"
                      onClick={() => setAmount(availableToken)}
                    >
                      Max
                    </button>
                  </div>

                  <button
                    disabled={Staked !== 0 ? true : false}
                    onClick={stakeTokenFunc}
                    className="btn-green-gradient"
                  >
                    Stake Token
                  </button>
                </div>
              </Col>
              <Col lg="7">
                <div>
                  <CountdownTimer
                    futureUnixTimestampTitle="Time Remaining"
                    futureUnixTimestamp={remainingTime}
                    totalStakedTitle="Total Staked"
                    claimableTokens={claimableTokens}
                    totalStaked={totalStaked}
                    Staked={Staked}
                  />
                </div>
              </Col>
            </Row>
            {/* {apyData && apyData.length && <APYTable APYData={apyData} />} */}
          </div>
        </div>
      )}
    </>
  );
};

export default Stake;
