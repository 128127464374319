import UpdateTier from "./update";
import { useCallback, useEffect, useState } from "react";
import TierForm from "./index";
import { getVaultData } from "src/util/program/functions";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("create");
  const [accountExist, setAccountExist] = useState(false);
  const { connection } = useConnection();
  const wallet = useWallet();

  const fetchData = useCallback(async () => {
    const tiersD = await getVaultData(connection, wallet);
    if (tiersD) {
      setActiveTab("update");
      setAccountExist(true);
    }
  }, [wallet, connection]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="tabs-container main-padding-area stake-container">
      <div className="small-container">
        <div className="tabs-content-area text-center mb-3">
          <div className="tabs tabs-content-area-info">
            {!accountExist && (
              <button
                className={`tab ${activeTab === "create" ? "active" : ""}`}
                onClick={() => setActiveTab("create")}
              >
                Create
              </button>
            )}
            <button
              className={`tab ${activeTab === "update" ? "active" : ""}`}
              onClick={() => setActiveTab("update")}
            >
              Update
            </button>
          </div>
        </div>

        <div className="tabs-content-wrapper">
          <div className="tab-content">
            {activeTab === "create" && (
              <div className="modal-content">
                <TierForm />
              </div>
            )}
            {activeTab === "update" && (
              <div className="modal-content">
                <UpdateTier />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
