import { PublicKey } from "@solana/web3.js";

const TOKEN_SEED = "token";
const BASE_SEED_USER_STATE = "user";
const STAKE_TOKEN_SEED = "stake_info";
const GLOBAL_CONFIG = "global-config";
const REWARD_VAULT_SEED = "reward_vault";
const STAKE_TREASURY_VAULTS_AUTHORITY = "stake_authority";
const REWARD_TREASURY_VAULTS_AUTHORITY = "reward_authority";
const priceUpdate = new PublicKey(
  "6B23K3tkb51vLZA14jcEQVCA1pfHptzEHFA93V5dYwbT"
);
const mintAddress = new PublicKey(
  "36PK2sqvb64dVevhg4xd83mqP2AX5B2mqyyrhtwAciEf"
);

const generateTierNames = (numTiers: number) => {
  return Array.from({ length: numTiers }, (_, index) => `Tier ${index}`);
};

// Generate tier names for 20 tiers
const tierNames = generateTierNames(20);
const COINGECKO_API_URL = "https://api.coingecko.com/api/v3/simple/price";

const getSolanaExplorerLink = (address: string, cluster: string = "mainnet") =>
  `https://explorer.solana.com/address/${address}?cluster=${cluster}`;

const createTiers = (numTiers: number) => {
  const tiers = [];
  for (let i = 0; i < numTiers; i++) {
    tiers.push({
      tokenRequirement1: i === 0 ? 0 : 10 * Math.pow(2, i - 1), // Exponential increase
      tokenRequirement2: i === 0 ? 0 : 25 * Math.pow(2, i - 1), // Exponential increase
      commitmentTime: i === 0 ? 0 : 30 * i, // Incremental commitment time (e.g., in days)
      clientCommission: i === 0 ? 50 : 30, // Static for tiers > 0
      apy: i === 0 ? 0 : Math.floor(Math.random() * 100), // Generates APY between 0-9900
      accessToPremiumNodes: i >= 1, // Premium access from Tier 1 onward
      referralProgram: i === 0 ? 0 : i < 3 ? 1 : 2, // Enhanced rates for higher tiers
    });
  }
  return tiers;
};

// Generate 20 tiers
const initialTierData = createTiers(20);
const initialapyData = [
  {
    duration: 0,
    apy: 0,
    slashingPenalties: 50,
  },
  {
    duration: 0,
    apy: 0,
    slashingPenalties: 50,
  },
  {
    duration: 0,
    apy: 0,
    slashingPenalties: 50,
  },
];

export {
  mintAddress,
  initialapyData,
  initialTierData,
  getSolanaExplorerLink,
  GLOBAL_CONFIG,
  tierNames,
  STAKE_TREASURY_VAULTS_AUTHORITY,
  BASE_SEED_USER_STATE,
  priceUpdate,
  STAKE_TOKEN_SEED,
  TOKEN_SEED,
  REWARD_VAULT_SEED,
  REWARD_TREASURY_VAULTS_AUTHORITY,
  COINGECKO_API_URL,
};
