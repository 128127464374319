import { Connection, PublicKey } from "@solana/web3.js";
import { programInstance } from "./program";
import {
  BASE_SEED_USER_STATE,
  GLOBAL_CONFIG,
  mintAddress,
  REWARD_TREASURY_VAULTS_AUTHORITY,
  REWARD_VAULT_SEED,
} from "../consts";
import { utils } from "@coral-xyz/anchor";
import {
  getAssociatedTokenAddressSync,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { enqueueSnackbar } from "notistack";
import { parseErrorFromIDL } from "./validateAccounts";

export const claimRewards = async (connection: Connection, wallet: any) => {
  try {
    const program: any = programInstance(connection, wallet);
    const [globalConfig] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(GLOBAL_CONFIG)],
      program.programId
    );
    const [userState] = PublicKey.findProgramAddressSync(
      [
        utils.bytes.utf8.encode(BASE_SEED_USER_STATE),
        globalConfig.toBuffer(),
        wallet.publicKey.toBuffer(),
      ],
      program.programId
    );
    const [rewardTreasury] = PublicKey.findProgramAddressSync(
      [
        utils.bytes.utf8.encode(REWARD_TREASURY_VAULTS_AUTHORITY),
        mintAddress.toBuffer(),
      ],
      program.programId
    );
    const [rewardVault] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(REWARD_VAULT_SEED), globalConfig.toBuffer()],
      program.programId
    );
    const associatedTokenAccountB = getAssociatedTokenAddressSync(
      mintAddress,
      wallet.publicKey,
      true
    );
    await program.methods
      .claimRewards()
      .accounts({
        globalConfig,
        userState,
        rewardMint: mintAddress,
        rewardTreasury,
        depositer: wallet.publicKey,
        payerRewardTokenAta: associatedTokenAccountB,
        tokenProgram: TOKEN_PROGRAM_ID,
        rewardVault,
      })
      .signers([])
      .rpc();
  } catch (error: any) {
    const parsedError = await parseErrorFromIDL(error);
    enqueueSnackbar(parsedError.message || error.message, {
      variant: "error",
      autoHideDuration: 6000,
    });
    return false;
  }
};
