import {
  Connection,
  PublicKey,
  SystemProgram,
  Transaction,
} from "@solana/web3.js";
import { programInstance } from "./program";
import { BN, utils } from "@coral-xyz/anchor";
import { TOKEN_PROGRAM_ID } from "@coral-xyz/anchor/dist/cjs/utils/token";
import { enqueueSnackbar } from "notistack";
import { getAssociatedTokenAddressSync } from "@solana/spl-token";
import {
  BASE_SEED_USER_STATE,
  GLOBAL_CONFIG,
  mintAddress,
  priceUpdate,
  STAKE_TREASURY_VAULTS_AUTHORITY,
} from "../consts";
import {
  checkUserPdaAccountExistence,
  parseErrorFromIDL,
} from "./validateAccounts";

export const initializeUserState = async (
  connection: Connection,
  wallet: any
) => {
  try {
    const program: any = programInstance(connection, wallet);

    const [globalConfig] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(GLOBAL_CONFIG)],
      program.programId
    );

    const [userState] = PublicKey.findProgramAddressSync(
      [
        utils.bytes.utf8.encode(BASE_SEED_USER_STATE),
        globalConfig.toBuffer(),
        wallet.publicKey.toBuffer(),
      ],
      program.programId
    );

    const initUserStateTx = await program.methods
      .initializeUser()
      .accounts({
        user: wallet.publicKey,
        globalConfig,
        userState,
        systemProgram: SystemProgram.programId,
      })
      .transaction();

    return initUserStateTx;
  } catch (error: any) {
    const parsedError = await parseErrorFromIDL(error);
    enqueueSnackbar(parsedError.message || error.message, {
      variant: "error",
      autoHideDuration: 6000,
    });
    return false;
  }
};

export const stakeToken = async (
  connection: Connection,
  wallet: any,
  amount: number,
  apy: number
) => {
  try {
    const program: any = programInstance(connection, wallet);

    const [stakeTreasury] = PublicKey.findProgramAddressSync(
      [
        utils.bytes.utf8.encode(STAKE_TREASURY_VAULTS_AUTHORITY),
        mintAddress.toBuffer(),
      ],
      program.programId
    );

    const [globalConfig] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(GLOBAL_CONFIG)],
      program.programId
    );

    const [userState] = PublicKey.findProgramAddressSync(
      [
        utils.bytes.utf8.encode(BASE_SEED_USER_STATE),
        globalConfig.toBuffer(),
        wallet.publicKey.toBuffer(),
      ],
      program.programId
    );

    const associatedTokenAccountB = getAssociatedTokenAddressSync(
      mintAddress,
      wallet.publicKey,
      true
    );

    const stakeTx = await program.methods
      .stake(new BN(amount))
      .accounts({
        globalConfig,
        userState,
        stakeTreasury,
        payer: wallet.publicKey,
        tokenMint: mintAddress,
        tokenProgram: TOKEN_PROGRAM_ID,
        userStakeAccount: associatedTokenAccountB,
        priceUpdate,
        systemProgram: SystemProgram.programId,
      })
      .transaction();

    return stakeTx;
  } catch (error: any) {
    const parsedError = await parseErrorFromIDL(error);
    enqueueSnackbar(parsedError.message || error.message, {
      variant: "error",
      autoHideDuration: 6000,
    });
    return false;
  }
};

export const initializeUserStateAndStakeToken = async (
  connection: Connection,
  wallet: any,
  amount: number,
  index: number
) => {
  try {
    const program: any = programInstance(connection, wallet);
    let transaction = new Transaction();
    if (
      await checkUserPdaAccountExistence(
        connection,
        wallet.publicKey,
        program.programId
      )
    ) {
      const tx = await stakeToken(connection, wallet, amount, index);
      if (tx) {
        transaction.add(tx);
      }

      transaction.feePayer = wallet.publicKey;
      transaction.recentBlockhash = (
        await connection.getLatestBlockhash()
      ).blockhash;

      // Sign the transaction - THIS IS THE KEY ADDITION
      if (wallet.signTransaction) {
        transaction = await wallet.signTransaction(transaction);
      } else {
        throw new Error("Wallet doesn't support transaction signing");
      }

      // Serialize and send the signed transaction
      const serializedTransaction = transaction.serialize();
      const tran = await connection.sendRawTransaction(serializedTransaction, {
        skipPreflight: false,
        preflightCommitment: "confirmed",
      });

      // Wait for confirmation
      await connection.confirmTransaction(tran, "confirmed");
      enqueueSnackbar("Token staked successfully", {
        variant: "success",
        autoHideDuration: 6000,
      });

      return true;
    } else {
      let tx = await initializeUserState(connection, wallet);
      if (tx) {
        transaction.add(tx);
        transaction.feePayer = wallet.publicKey;
        transaction.recentBlockhash = (
          await connection.getLatestBlockhash()
        ).blockhash;
      }
      let tx1 = await stakeToken(connection, wallet, amount, index);

      if (tx1) {
        transaction.add(tx1);
        transaction.feePayer = wallet.publicKey;
        transaction.recentBlockhash = (
          await connection.getLatestBlockhash()
        ).blockhash;
      }

      // Sign the transaction - THIS IS THE KEY ADDITION
      if (wallet.signTransaction) {
        transaction = await wallet.signTransaction(transaction);
      } else {
        throw new Error("Wallet doesn't support transaction signing");
      }

      // Serialize and send the signed transaction
      const serializedTransaction = transaction.serialize();
      const tran = await connection.sendRawTransaction(serializedTransaction, {
        skipPreflight: false,
        preflightCommitment: "confirmed",
      });

      // Wait for confirmation
      await connection.confirmTransaction(tran, "confirmed");
      enqueueSnackbar("Token staked successfully", {
        variant: "success",
        autoHideDuration: 6000,
      });

      return true;
    }
  } catch (error: any) {
    const parsedError = await parseErrorFromIDL(error);
    enqueueSnackbar(parsedError.message || error.message, {
      variant: "error",
      autoHideDuration: 6000,
    });

    return false;
  }
};

export const getStakerProfile = async (connection: Connection, wallet: any) => {
  try {
    const program = programInstance(connection, wallet);

    const [globalConfig] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(GLOBAL_CONFIG)],
      program.programId
    );

    const [stakerProfile] = PublicKey.findProgramAddressSync(
      [
        utils.bytes.utf8.encode(BASE_SEED_USER_STATE),
        globalConfig.toBuffer(),
        wallet.publicKey.toBuffer(),
      ],
      program.programId
    );

    const stakerProfileData = await program.account.userState.fetch(
      stakerProfile
    );
    console.log(
      stakerProfileData.availableTier,
      "stakerProfileDatastakerProfileData"
    );
    return stakerProfileData;
  } catch (error: any) {
    return false;
  }
};

export const getAllStakerData = async (connection: Connection, wallet: any) => {
  try {
    const program = programInstance(connection, wallet);

    const stakerProfileData = await program.account.userState.all();

    if (stakerProfileData.length > 0) {
      const formattedProfiles = stakerProfileData
        .map((profile) => ({
          ...profile,
          account: {
            ...profile.account,
            tier: {
              ...profile.account.availableTier,
              tokenRequirements:
                profile.account.availableTier.tokenRequirements.map(
                  (req: any) => req.toNumber()
                ),
              commitmentTime:
                profile.account.availableTier.commitmentTime.toNumber(),
              name: profile.account?.availableTier.name || "Tier 0",
            },
            endLockTime: profile.account.lockDuration,
            timestamp: profile.account.updatedTimestamp.toNumber(),
            claimAmount: profile.account.claimedToken.toNumber(),
            stakeAmount: profile.account.currentStakedToken.toNumber(),
            totalStakedToken: profile.account.totalStakedToken.toNumber(),
            updatedTimestamp: profile.account.updatedTimestamp.toNumber(),
            identity: profile.account.userAddress.toBase58(),
          },
        }))
        .sort(
          (a, b) => b.account.updatedTimestamp - a.account.updatedTimestamp
        );

      return formattedProfiles;
    } else {
      return false;
    }
  } catch (error: any) {
    return false;
  }
};
