import { Connection, PublicKey, Transaction } from "@solana/web3.js";
import { programInstance } from "./program";
import { utils } from "@coral-xyz/anchor";
import { enqueueSnackbar } from "notistack";
import { GLOBAL_CONFIG } from "../consts";
import { parseErrorFromIDL } from "./validateAccounts";
import { initialapyData } from "../consts/index";

export const getApyData = async (connection: Connection, wallet: any) => {
  try {
    const program = programInstance(connection, wallet);
    const [vault] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(GLOBAL_CONFIG)],
      program.programId
    );
    const data = await program.account.globalConfig.fetch(vault);

    const mapTier = (tier: any) => ({
      apy: tier.apy ? tier.apy / 100 : 0,
      commitmentTime: tier.commitmentTime.toNumber() / (24 * 60 * 60) || 0,
    });

    return data.tiers.map(mapTier);
  } catch (error: any) {
    return [];
  }
};

export const AddAPY = async (
  connection: Connection,
  wallet: any,
  apyData: typeof initialapyData
) => {
  try {
    const program: any = programInstance(connection, wallet);

    const [globalConfig] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(GLOBAL_CONFIG)],
      program.programId
    );

    // Create new transaction
    let addApyTx = new Transaction();

    // Add instructions to transaction
    for (let index = 0; index < apyData.length; index++) {
      const apy = apyData[index].apy * 100;
      const apyTx = await program.methods
        .addApyEntries(
          apyData[index].duration,
          apy,
          apyData[index].slashingPenalties
        )
        .accounts({
          globalConfig,
          globalAdmin: wallet.publicKey,
        })
        .transaction();

      addApyTx.add(apyTx);
    }

    // Set fee payer and get recent blockhash
    addApyTx.feePayer = wallet.publicKey;
    addApyTx.recentBlockhash = (
      await connection.getLatestBlockhash()
    ).blockhash;

    // Sign the transaction - THIS IS THE KEY ADDITION
    if (wallet.signTransaction) {
      addApyTx = await wallet.signTransaction(addApyTx);
    } else {
      throw new Error("Wallet doesn't support transaction signing");
    }

    // Serialize and send the signed transaction
    const serializedTransaction = addApyTx.serialize();

    const tx = await connection.sendRawTransaction(serializedTransaction, {
      skipPreflight: false,
      preflightCommitment: "confirmed",
    });

    // Wait for confirmation
    await connection.confirmTransaction(tx, "confirmed");

    enqueueSnackbar("APY data initialized successfully.", {
      variant: "success",
      autoHideDuration: 6000,
    });

    return true;
  } catch (error: any) {
    const parsedError = await parseErrorFromIDL(error);
    enqueueSnackbar(parsedError.message || error.message, {
      variant: "error",
      autoHideDuration: 6000,
    });
    return false;
  }
};

export const updateApy = async (
  connection: Connection,
  wallet: any,
  apyData: typeof initialapyData
) => {
  try {
    const program: any = programInstance(connection, wallet);
    const [globalConfig] = PublicKey.findProgramAddressSync(
      [utils.bytes.utf8.encode(GLOBAL_CONFIG)],
      program.programId
    );

    // Create new transaction
    let addApyTx = new Transaction();

    for (let index = 0; index < apyData.length; index++) {
      const apy = apyData[index].apy * 100;

      const apyTx = await program.methods
        .updateApyEntries(
          index,
          apyData[index].duration,
          apy,
          apyData[index].slashingPenalties
        )
        .accounts({
          globalConfig,
          globalAdmin: wallet.publicKey,
        })
        .transaction();

      addApyTx.add(apyTx);
    }

    // Set fee payer and get recent blockhash
    addApyTx.feePayer = wallet.publicKey;
    addApyTx.recentBlockhash = (
      await connection.getLatestBlockhash()
    ).blockhash;

    // Sign the transaction - THIS IS THE KEY ADDITION
    if (wallet.signTransaction) {
      addApyTx = await wallet.signTransaction(addApyTx);
    } else {
      throw new Error("Wallet doesn't support transaction signing");
    }

    // Serialize and send the signed transaction
    const serializedTransaction = addApyTx.serialize();

    const tx = await connection.sendRawTransaction(serializedTransaction, {
      skipPreflight: false,
      preflightCommitment: "confirmed",
    });

    // Wait for confirmation
    await connection.confirmTransaction(tx, "confirmed");
    enqueueSnackbar("APY data initialized successfully.", {
      variant: "success",
      autoHideDuration: 6000,
    });
    return true;
  } catch (error: any) {
    const parsedError = await parseErrorFromIDL(error);
    enqueueSnackbar(parsedError.message || error.message, {
      variant: "error",
      autoHideDuration: 6000,
    });
    return false;
  }
};
