import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { initialTierData } from "src/util/consts";
import { getVaultData } from '../../util/program/functions';
import { useCallback, useEffect, useState } from 'react';
import TokenPrice from './TokenPrice';
const TierTable = () => {
    const [initTierData, setIntiData] = useState(initialTierData)

    const wallet = useWallet();
    const { connection } = useConnection();
    const fetchData = useCallback(async () => {
        const tiersD = await getVaultData(connection, wallet);
        if (tiersD)
            setIntiData(tiersD);
        // eslint-disable-next-line
    }, [wallet, connection]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div className="stake-container main-padding-area stake-table">
            <div className='container-width'>
                <div>
                    <TokenPrice />
                </div>
                <div className='line-spacer'></div>
                <div>
                    <h3 className='text-white mb-4'>Tier Data</h3>
                    <div className='table-responsive'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Tier Name</th>
                                    <th>Token Requirement min</th>
                                    <th>Token Requirement max</th>
                                    <th>Commitment Time</th>
                                    <th>APY</th>
                                    <th>Client Commission </th>
                                    <th>Access to Premium Nodes</th>
                                    <th>Referral Program</th>
                                </tr>
                            </thead>
                            <tbody>
                                {initTierData.map((tier, index) => (
                                    <tr key={index}>
                                        <td>Tiers {index}</td>
                                        <td>{tier.tokenRequirement1}  $</td>
                                        <td>{tier.tokenRequirement2} $</td>
                                        <td>{(tier.commitmentTime)}</td>
                                        <td>{tier.apy} (%)</td>
                                        <td>{tier.clientCommission} (%)</td>
                                        <td>{tier.accessToPremiumNodes ? "Yes" : "No"}</td>
                                        <td>{tier.referralProgram === 0 ? "false" : tier.referralProgram === 1 ? "true" : "Enhanced"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TierTable;
